import React from 'react';
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground } from '../../components/Wrapper';
import { VideoFrame } from '../../components/VideoFrame';
import { Quote } from '../../components/Quote';
import { PageTitle} from '../../components/PageTitle';
import Default from '../../components/Layouts/Default';

import QLogo from './customerLogos/qLogo.svg';

const AboutPage = () => (
  <Default>
    <WrapperBackground color={colors.trulyDark} background="customersBanner">
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 2 / 3]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            OUR CUSTOMERS
          </Title3>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            WHY THE BEST SALES TEAMS USE TRULY
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <Quote
      customerPhoto="zoey"
      color={colors.accentFog}
      quoteText="Truly gives all members of my team an amazing experience, and everyone trusts them. I would recommend Truly to anyone.”"
      quoteAttribution="Zoey Gold, Square"
    />

    <WrapperBackground color={colors.trulyDark} background="managedByQ" gradientType="light">
      <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width={[1, 1 / 2]}>
          <CaseStudyCardLogo src={QLogo} />
          <Large as="p" color={colors.trulyDark} my="1.5rem">
            Hear how Managed By Q's IT team consolidated 4 systems into 1 with Truly and created significant cost
            savings for their business.
          </Large>
          <VideoFrame background="q" videoLink="https://player.vimeo.com/video/264333140?autoplay=1" />
        </Box>
      </Section>
    </WrapperBackground>

    <Quote />
  </Default>
);

export default AboutPage;

const CaseStudyCardLogo = styled.img`
  height: 32pt;
  width: auto;
  margin-top: 16pt;
`;
